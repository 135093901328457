import Image from 'next/image'

import { Box } from '@chakra-ui/layout'
import { useColorMode } from '@chakra-ui/react'

import { HarvardMedicalSchoolLogo } from './HarvardMedicalSchoolLogo'
import { JohnHopkinsLogo } from './JohnHopkinsLogo'
import { McGillLogo } from './McGillLogo'
import { PennLogo } from './PennLogo'
import { StanfordMedicineLogo } from './StanfordMedicineLogo'

export const LogoCloudSection = () => {
  const isLight = useColorMode().colorMode === 'light'
  return (
    <div className="w-7xl mx-auto max-w-7xl px-6 lg:px-8">
      <h2 className="text-center text-gray-600 sm:text-lg">
        Trusted by 800,000+ medical professionals from leading institutions
      </h2>
      <div className="w-7xl mx-auto mt-8 flex max-w-full flex-col flex-wrap items-center justify-center gap-10 md:flex-row xl:flex-nowrap xl:gap-16">
        <Box opacity="0.4">
          <JohnHopkinsLogo fill={isLight ? 'black' : 'white'} size={156} />
        </Box>

        {isLight ? (
          <Image
            src="/marketing/ucsf-logo.png"
            className="col-span-2 max-h-9 w-auto object-contain opacity-40 saturate-0 sm:w-full md:w-auto lg:col-span-1"
            width={150}
            height={50}
            alt="UCSF"
          />
        ) : (
          <Image
            src="/marketing/ucsf-logo-light.png"
            className="col-span-2 max-h-9 w-auto object-contain opacity-40 saturate-0 sm:w-full md:w-auto  lg:col-span-1"
            width={150}
            height={50}
            alt="UCSF"
          />
        )}
        <div className="col-span-2 flex max-h-9 w-auto justify-center object-contain opacity-40 lg:col-span-1">
          <HarvardMedicalSchoolLogo
            fill={isLight ? 'black' : 'white'}
            fillAlternate={isLight ? 'white' : 'black'}
            size={128}
          />
        </div>
        <div className="col-span-2 flex max-h-9 w-auto justify-center object-contain opacity-40 grayscale lg:col-span-1">
          <PennLogo textFill={isLight ? 'black' : 'white'} size={100} />
        </div>

        <div className="col-span-2 hidden max-h-9 w-auto justify-center object-contain opacity-40 grayscale md:block lg:col-span-1">
          <McGillLogo
            fill={isLight ? 'black' : 'white'}
            fillAlternate={isLight ? 'white' : 'black'}
            size={110}
          />
        </div>

        <div className="col-span-2 hidden max-h-9 w-auto justify-center object-contain opacity-40 grayscale md:block lg:col-span-1">
          <StanfordMedicineLogo
            fill={isLight ? 'black' : 'white'}
            fillAlternate={isLight ? 'white' : 'black'}
            size={124}
          />
        </div>
      </div>
    </div>
  )
}
